import { Component, Vue, Watch, Prop, Ref } from 'vue-property-decorator';

// const DEG2RAD = Math.PI/180;
const RAD2DEG = 180/Math.PI;

@Component({
  name: 'CircularMenu',
  components: {
      //
  }
})
export default class CircularMenu extends Vue {
    @Prop({default: 100}) readonly radius!: number;
    @Prop({default: 0}) readonly angle!: number;
    @Prop({default: false}) readonly clockwise!: boolean;
    @Prop({default: 0}) readonly margin!: number;
    @Prop() readonly justify!: 'start' | 'end' | 'center' | 'space';
    @Ref() readonly satellites!: HTMLElement;
    observer: MutationObserver | null = null;
    mounted(){
        this.recalculate();
        this.observer = new MutationObserver(this.recalculate);
        this.observer.observe(this.satellites, {
            attributes: true,
            childList: true,
            subtree: false
        });
    }
    @Watch('radius')
    @Watch('angle')
    onContentChange(){
        this.recalculate();
    }
    recalculate(mutations?: MutationRecord[]){
        this.$nextTick(() => {
            if (!this.$slots.default){
                return;
            }
            (this.$el as HTMLElement).style.minHeight = `${this.radius*2}px`;
            (this.$el as HTMLElement).style.minWidth = `${this.radius*2}px`;
            const satellites = this.$slots.default.filter(node => node.tag).map(node => {
                return {
                    element: node.elm as HTMLElement,
                    angle: 0,
                    size: 0,
                }
            });
            let totalSize = 0;
            if (this.justify != 'space') {
                satellites.forEach((satellite, i) => {
                    satellite.size = Math.sqrt(satellite.element.clientWidth*satellite.element.clientHeight) + this.margin*2;
                    satellite.angle = (totalSize+satellite.size*0.5)/Number(this.radius) * RAD2DEG;
                    totalSize += satellite.size;
                });
            }
            satellites.forEach((satellite, i) => {
                switch (this.justify) {
                    case 'end':
                        satellite.angle -= totalSize/Number(this.radius) * RAD2DEG;
                        break;
                    case 'center':
                        satellite.angle -= totalSize*0.5/Number(this.radius) * RAD2DEG;
                        break;
                    case 'space':
                        satellite.angle = i/satellites.length * 360;
                        break;
                }
                if (!this.clockwise){
                    satellite.angle = -satellite.angle;
                }
                satellite.angle += Number(this.angle);
                satellite.element.style.transform = `translate(-50%, -50%) rotateZ(${satellite.angle}deg) translateY(-${this.radius}px) rotateZ(${-satellite.angle}deg)`;
            });
        });
    }
}