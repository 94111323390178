// @ts-nocheck
import { Component, Vue, Watch, Mixins, Ref } from 'vue-property-decorator';
import 'ol/ol.css';
import {
    Map,
    View,
    Feature,
} from 'ol';
import {
    GPX,
} from 'ol/format';
import {
    OSM,
    Vector as VectorSourceIncomplete,
    TileWMS,
    XYZ,
} from 'ol/source';
import {
    Tile as TileLayer,
    Vector as VectorLayer,

} from 'ol/layer';
import {
    Circle as CircleStyle,
    Fill,
    Stroke,
    Style,
    Icon,
    Text,
} from 'ol/style';
import {
    Polygon,
    Point,
} from 'ol/geom';
import {Select} from 'ol/interaction';
import {click as Click} from 'ol/events/condition';
import {circular} from 'ol/geom/Polygon';
import * as Extent from 'ol/extent';
import {fromLonLat} from 'ol/proj';
import Control from 'ol/control/Control';

declare global {
  interface Window {
    // hoverFeatureId: string;
    highlightedFeature: any;
  }
}
class VectorSource extends VectorSourceIncomplete{
  featuresRtree_!: {
    items_: {
      [key: number]: unknown;
    };
  }
}
const uriTest = /\w+:(\/?\/?)[^\s]+/

const style = {
  'Point': new Style({
    image: new CircleStyle({
      fill: new Fill({
        color: 'rgba(255,255,0,0.4)',
      }),
      radius: 5,
      stroke: new Stroke({
        color: '#ff0',
        width: 1,
      }),
    }),
  }),
  'LineString': new Style({
    stroke: new Stroke({
      color: '#f00',
      width: 3,
    }),
  }),
  // 'LinearRing': new Style({
  //   stroke: new Stroke({
  //     color: '#f00',
  //     width: 3,
  //   }),
  // }),
  'MultiLineString': new Style({
    stroke: new Stroke({
      color: '#f00',
      width: 3,
    }),
  }),
};
const styles = {
  '10': new Style({
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({color: '#666666'}),
      stroke: new Stroke({color: '#bada55', width: 1}),
    }),
  }),
  '20': new Style({
    image: new CircleStyle({
      radius: 10,
      fill: new Fill({color: '#666666'}),
      stroke: new Stroke({color: '#bada55', width: 1}),
    }),
  }),
  '50': new Style({
    image: new CircleStyle({
      radius: 25,
      fill: new Fill({color: '#008800'}),
      // stroke: new Stroke({color: '#bada55', width: 1}),
    }),
  }),
};
// const tracks: {
//   name: string;
//   featureId: string;
// }[] = [];
const nullTrack: Track = {
  name: 'Error',
  difficulty: 'none',
  gpx: '',
}
const colors = {
  // white: '#fff',
  // none: '#888',
  black: '#111',
  red: '#be1622',
  blue: '#2c4b9b',
  green: '#006633',
  // grey: '#3c3c3b',
  yellow: '#fcea10',
}

const colorFills: {
  [key: string]: Fill;
} = {};

for (const [colorName,color] of Object.entries(colors)){
  colorFills[colorName] = new Fill({color});
}

const trackStyles: {
  [key: string]: Style;
} = {};

for (const [colorName,color] of Object.entries(colors)){
  trackStyles[colorName] = new Style({
    stroke: new Stroke({
      color,
      width: 3,
    }),
  });
}
trackStyles.fade = new Style({
  stroke: new Stroke({
    color: '#fff3',
    width: 6,
  }),
});
trackStyles.highlight = new Style({
  stroke: new Stroke({
    color: '#fff',
    width: 10,
  }),
});

const markerIcons: {
  [key: string]: Icon;
} = {};
const markerStyles: {
  [key: string]: Style;
} = {};

for (const [colorName,color] of Object.entries(colors)){
  markerIcons[colorName] = new Icon({
    anchor: [0.5, 0.9],
    opacity: 1,
    // src: require(`../assets/pins/red.svg`),
    src: require(`../assets/pins/${colorName}.svg`),
    scale: 0.5,
    // color,
  });
  markerStyles[colorName] =  new Style({
    image: markerIcons[colorName],
    // image: new CircleStyle({
    //   radius: 25,
    //   fill: new Fill({color: colors[colorName]}),
    //   // stroke: new Stroke({color: '#bada55', width: 1}),
    // }),
  });

}
const markerTextStroke = new Stroke({
  color: "#eee",
  width: 1
});

const locationStyle = new Style({
  image: new CircleStyle({
    fill: new Fill({
      color: '#E000A7',
    }),
    radius: 15,
    stroke: new Stroke({
      color: '#fff',
      width: 2,
    }),
  }),
});

const gpxFormat = new GPX();
// var marker = new Feature({
//   geometry: new Point(proj.fromLonLat([lon, lat])),
// });
// console.log(source.featuresRtree_.items_);

// source.addFeature(new Feature({
//   geometry: new Polygon([]),
//   labelPoint: new Point(fromLonLat([10, 60.2])),
//   name: 'My Polygon',
// }));
@Component({
  name: 'MapView',
  components: {
    // 'file-column': require('@/components/FileColumn.vue').default,
  }
})
export default class MapView extends Vue {
  map!: Map;
  tracks: Track[] = [];
  selectedTrack: Track | null = null;
  cursor = '';
  select!: Select;
  zoomWas = 0;
  mounted(){
    this.getUserLocation(11);
    const locate = document.createElement('div');
    locate.className = 'ol-control ol-unselectable locate';
    locate.innerHTML = '<button title="Your location">●</button>';
    locate.addEventListener('click', () => {
        this.getUserLocation();
    });
    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OSM(),
          opacity: 1,
        }),
        new TileLayer({
          // extent: [-13884991, 2870341, -7455066, 6338219],
          // source: new TileWMS({
          //   url: "http://opencache.statkart.no/gatekeeper/gk/gk.open?",
          //   // url: 'https://ahocevar.com/geoserver/wms',
          //   params: {'LAYERS': 'topo2'},
          //   // serverType: 'geoserver',
          //   // Countries have transparency, so do not fade tiles:
          //   transition: 0,
          // }),
          source: new XYZ({
              url: 'https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=topo4&zoom={z}&x={x}&y={y}',
              attributions: '<a href="http://www.kartverket.no/">Kartverket</a>'
          })
        }),
      ],
      view: new View({
        center: fromLonLat([10, 60.2]),
        zoom: 8,
        // extent: Extent.createOrUpdate(9, 58, 12, 65),
      })
    });
    this.map.addControl(
        new Control({
          element: locate
        })
    );
    this.map.on('moveend', event => {
      this.updateTrackVisibilities();
    });
    this.fetchTracks();
    // this.map.on('pointermove', event => {
    //   if (event.dragging) {
    //     return;
    //   }
    //   const pixel = this.map.getEventPixel(event.originalEvent);
    //   this.infoAtPixel(pixel);
    // });
    this.select = new Select({
      condition: Click,
      style: null as any,
      hitTolerance: 5,
    });
    this.select.on('select', event => {
      const feature = event.target.getFeatures().array_[0];
      // console.log('select', feature);
      // console.log(this.tracks[feature.values_.i]);
      if (!feature){
        return;
      }
      // const selection = this.trackByFeatureId(feature.ol_uid);
      const track = this.tracks[feature.values_.i];
      this.selectTrack(track);
      // const center = this.map.getView().getCenter();
      // this.$nextTick(() => {
      //   this.map.getView().setCenter(center);
      // });
      // document.getElementById('status').innerHTML =
      //   '&nbsp;' +
      //   e.target.getFeatures().getLength() +
      //   ' selected features (last operation selected ' +
      //   e.selected.length +
      //   ' and deselected ' +
      //   e.deselected.length +
      //   ' features)';
    });
    this.map.addInteraction(this.select);
  }
  selectTrack(track?: Track){
    const selectedTrackWas = this.selectedTrack;
    if (track === this.selectedTrack){
      return;
    }
    this.selectedTrack = null; // Hide previous before showing new
    this.$nextTick(() => {
      this.selectedTrack = track;
      this.updateTrackVisibilities();
      if (!track){
        return;
      }
      this.$ga.page(track.name);
    });
  }
  updateTrackVisibilities(track: Track){
      const viewExtent = this.map.getView().calculateExtent(this.map.getSize());
      const zoom = Math.floor(this.map.getView().getZoom());
      if (zoom !== this.zoomWas){
        this.zoomWas = zoom;
      }
      const tracksInView: Track[] = [];
      for (const track of this.tracks){
        if (!track.extent){
          continue;
        }
        const zoom = Math.floor(this.map.getView().getZoom());
        const inView = Extent.intersects(track.extent, viewExtent);
        if (inView){
          tracksInView.push(track);
        }
        // console.log(track.name, show);
      }
      for (const track of this.tracks){
        let show = track === this.selectedTrack
        show |= zoom >= 12 && tracksInView.length < 14 && tracksInView.includes(track);
        show |= zoom >= 10 && tracksInView.length < 5 && tracksInView.includes(track);
        this.toggleTrack(track, show);
      }
  }
  toggleTrack(track: Track, show: boolean){
    if (show){
      if (!track.showing){
        track.showing = true;
        if (!track.layer){
          const source = new VectorSource({
              url: '/tracks/'+track.gpx,
              format: new GPX(),
          })
          track.layer = new VectorLayer({
            source,
            style: trackStyles[track.difficulty],
            zIndex: 2,
          });
        }
        this.map.addLayer(track.layer);
      }
      if (this.selectedTrack === track && !track.layerHightlight){
        track.layerHightlight = new VectorLayer({
          source: track.layer.getSource(),
          style: trackStyles.highlight,
          zIndex: 1,
        });
        this.map.addLayer(track.layerHightlight);
        this.map.removeLayer(track.layerFade);
        track.layerFade = undefined;
      } else if (this.selectedTrack !== track && !track.layerFade) {
          track.layerFade = new VectorLayer({
            source: track.layer.getSource(),
            style: trackStyles.fade,
            zIndex: 3,
          });
        this.map.addLayer(track.layerFade);
        this.map.removeLayer(track.layerHightlight);
        track.layerHightlight = undefined;
      }
      // track.layerBase.getSource().getParams();
    } else {
      if (!track.showing){
        return;
      }
      track.showing = false;
      this.map.removeLayer(track.layerBase);
      this.map.removeLayer(track.layer);
    }
  }
  infoAtPixel(pixel: ol.Pixel) {
    const features = this.map.getFeaturesAtPixel(pixel, {
      hitTolerance: 5,
    });
    if (window.highlightedFeature === features[0]){
      return;
    }
    const prevHighlight = window.highlightedFeature;
    window.highlightedFeature = features[0];
    if (window.highlightedFeature){
      // window.highlightedFeature.changed();
      this.cursor = 'pointer';
    } else {
      this.cursor = '';
    }
    // prevHighlight && prevHighlight.changed();
  }
  fetchTracks(){
    var headers = new Headers([
      ['pragma', 'no-cache'],
      ['cache-control', 'no-cache'],
    ]);
    fetch('/tracks.json', {
      headers,
    }).then(response => response.json()).then(data => this.tracks = data).then(() => {
      let i = -1;
      const features: Feature[] = [];
      this.tracks.forEach(track => {
        i++;
        if (track.bounds){
          const min = fromLonLat([track.bounds[2], track.bounds[0]]);
          const max = fromLonLat([track.bounds[3], track.bounds[1]]);
          track.extent = Extent.createOrUpdate(min[0], min[1], max[0], max[1]);
        }
        if (!track.start){
          return;
        }
        features.push(new Feature({
          'geometry': new Point(fromLonLat([track.start[1], track.start[0]])),
          'i': i,
          // 'size': 50,
        }));
      });
    const vectorSource = new VectorSource({
      features,
    });
    const vector = new VectorLayer({
      zIndex: 10,
      source: vectorSource,
      style: (feature) => {
        return markerStyles[this.tracks[feature.get('i')].difficulty];
        // return styles[feature.get('size')];
      },
    });
    this.map.addLayer(vector);
    });
  }
  featureToTrackMap: {
    [key: string]: Track;
  } = {}
  trackByFeatureId(featureId: string){
    return this.featureToTrackMap[featureId] || nullTrack;
  }
  trackStyle: ol.StyleFunction = (feature, resolution) => {
    return trackStyles[this.trackByFeatureId(feature.ol_uid).difficulty]
  }
  markerStyle: ol.StyleFunction = (feature, resolution) => {
    const track = this.trackByFeatureId(feature.ol_uid);
    return new Style({
      image: markerIcons[track.difficulty],
      text: new Text({
        text: this.trackByFeatureId(feature.ol_uid).name.toUpperCase(),
        backgroundFill: colorFills[track.difficulty],
        textAlign: 'center',
        // offsetX: 25,
        offsetY: -22,
        padding: [7, 5, 2, 8],
        font: '900 8mm sans-serif',
        textBaseline: 'bottom',
        // scale: feature === window.highlightedFeature ? 1.8 : 1.5,
        // scale: 1.5,
        scale: track === this.selectedTrack ? 2 : 1.5,
        fill: colorFills['white'],
        // stroke: markerTextStroke,
      })
    });
  }
  createMarker(coords: ol.Coordinate) {
    const marker = new Feature(new Point(coords));
    marker.setStyle(this.markerStyle.bind(this));
    return marker;
  }
  locationSource: VectorFeature;
  // locationAccuracy: Feature;
  // locationPoint: Feature;
  getUserLocation(maxZoom?: number){
    let initial = true;
    navigator.geolocation.getCurrentPosition(pos => {
          const coords = [pos.coords.longitude, pos.coords.latitude];
          const accuracy = circular(coords, pos.coords.accuracy);
          if (!this.locationSource){
            this.locationSource = new VectorSource();
            this.onUserLocation(this.locationSource);
          } else {
            this.locationSource.clear();
          }
          const locationAccuracy = new Feature({
            geometry: accuracy.transform('EPSG:4326', this.map.getView().getProjection()),

          });
          const locationPoint = new Feature({
            geometry: new Point(fromLonLat(coords)),
          });
          this.locationSource.addFeatures([
            locationAccuracy,
            locationPoint,
          ]);
          if (initial){
              initial = false;
              this.gotoSource(this.locationSource, maxZoom);
          }
    }, function(error) {
        console.error(error);
    }, {
      enableHighAccuracy: true
    });
  }
  onUserLocation(source: VectorSource){
    const layer = new VectorLayer({
      source,
      style: locationStyle,
      zIndex: 20,
    });
    this.map.addLayer(layer);
  }
  gotoSource(source: VectorSource, maxZoom=15){
      if (!source.isEmpty()) {
        this.map.getView().fit(source.getExtent(), {
          maxZoom: maxZoom,
          duration: 500
        });
      }
  }
  clearSelection(){
    this.selectedTrack = null;
    this.select.getFeatures().clear();
  }
  onClickDownload(track: Track){
    this.$ga.event('tracks', 'download', track.name);
  }
  onClickStrava(){
    this.$ga.event('tracks', 'strava', track.name);
  }
}
